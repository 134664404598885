export const Fields = [
    {
        key: 'agent_names',
        label:'Agent'
    },
    {
        key: 'specialist_names',
        label:'Specialis'
    },
    {
        key: 'admin_names',
        label:'Administrator'
    },
    {
        key: 'date_assigned',
        label:'Date',
        thClass: 'text-center'
    },
    {
        key: 'tracking',
        label: 'Tracking',
        thClass: 'text-center',
    },
    {
        key: 'edit',
        label: 'Edit',
        thClass: 'text-center',
    },
    {
        key: 'delete',
        label: 'Delete',
        thClass: 'text-center',
    },
    // {
    //     key: 'actions',
    //     label: 'Actions',
    //     thClass: 'text-center',
    // },

]
export const Filters = [
    {
        type: 'select',
        margin: true,
        showLabel: true,
        placeholder: 'Select a agent',
        label: 'Agents',
        class: 'font-small-3',
        model: null,
        reduce: 'value',
        selectText: 'text',
        cols: 12,
    },
    {
        type: 'select',
        margin: true,
        showLabel: true,
        label: 'Specialists',
        placeholder: 'Select a specialist',
        class: 'font-small-3',
        model: null,
        reduce: 'value',
        selectText: 'text',
        cols: 12,
    },
    {
        type: 'select',
        margin: true,
        showLabel: true,
        model: null,
        options: [],
        selectText: 'label',
        visible: true,
        label: 'Administrators',
        placeholder: 'Select  a administrator',
        class: 'font-small-3',
        reduce: 'value',
        selectText: 'text',
        cols: 12,
    }
]

export const FilterPrincipal ={
    type: 'input',
    inputType: 'text',
    placeholder: 'Agent...',
    model: '',
  }




// --------------- TRACKING

export const FieldsTracking = [
    {
        key:'agent',
        label:'Angent',
        thClass:'text-center',
        tdClass:'text-center'
    },
    {
        key:'specialist',
        label:'Specialist',
        thClass:'text-center',
        tdClass:'text-center'
    },
    {
        key:'admin',
        label:'Administrator',
        thClass:'text-center',
        tdClass:'text-center'
    },
    {
        key:'status',
        label:'Status',
        thClass:'text-center',
        tdClass:'text-center'
    },
    {
        key:'created_at',
        label:'Date',
        thClass:'text-center',
        tdClass:'text-center'
    }
]