var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',[_c('header-slot',{attrs:{"clients-search":false}})],1),_c('b-col',{attrs:{"cols":1}},[_c('b-button',{staticStyle:{"font-size":"15px"},attrs:{"variant":"success"},on:{"click":_vm.openNewRelationshipModal}},[_c('FeatherIcon',{attrs:{"icon":"PlusIcon","size":"20"}}),_vm._v(" Create ")],1)],1)],1),_c('b-card',[_c('filter-slot',{attrs:{"filter":_vm.filters,"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reload":_vm.refresh,"reset-all-filters":_vm.refresh},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"connectionUsersRelationshipTable",attrs:{"items":_vm.provider,"fields":_vm.fields,"table-class":"text-nowrap","responsive":"sm","busy":_vm.isBusy,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage,"show-empty":"","hover":""},on:{"update:busy":function($event){_vm.isBusy=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(date_assigned)",fn:function(ref){
var value = ref.value;
return [_c('center',[_vm._v(" "+_vm._s(_vm._f("myGlobalWithHour")(value))+" ")])]}},{key:"cell(tracking)",fn:function(ref){
var item = ref.item;
return [_c('center',[_c('FeatherIcon',{staticClass:"text-primary cursor-pointer",attrs:{"icon":"ListIcon","size":"20"},on:{"click":function($event){return _vm.openRelationshipTrackingModal(item.agent_id)}}})],1)]}},{key:"cell(edit)",fn:function(ref){
var item = ref.item;
return [_c('center',[_c('FeatherIcon',{staticClass:"text-warning cursor-pointer",attrs:{"icon":"Edit2Icon","size":"20"},on:{"click":function($event){return _vm.openEditRelationshipModal(item)}}})],1)]}},{key:"cell(delete)",fn:function(ref){
var item = ref.item;
return [_c('center',[_c('FeatherIcon',{staticClass:"text-danger cursor-pointer",attrs:{"icon":"Trash2Icon","size":"20"},on:{"click":function($event){return _vm.deleteRelationship(item.agent_id)}}})],1)]}}])})]},proxy:true}])})],1),(_vm.activeNewRelationshipModal)?_c('NewRelationshipModal',{attrs:{"active":_vm.activeNewRelationshipModal},on:{"close":function($event){return _vm.closeNewRelationshipModal($event)}}}):_vm._e(),(_vm.activeEditRelationshipModal)?_c('EditRelationshipModal',{attrs:{"active":_vm.activeEditRelationshipModal,"data":_vm.editData},on:{"close":function($event){return _vm.closeEditRelationshipModal($event)}}}):_vm._e(),(_vm.activeRelationshipTrackingModal)?_c('RelationshipTrackingModal',{attrs:{"active":_vm.activeRelationshipTrackingModal,"agentId":_vm.agentId},on:{"close":_vm.closeRelationshipTrackingModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }