<template>
    <b-modal v-model="isActive" @hidden="close(false)" title-tag="h3" title="Edit relationship" hide-footer>

        <b-row class="my-1">
            <b-col>
               <AgentHeader :agent="agent" />
            </b-col>
        </b-row>
        <ValidationObserver ref="observator">
            <!-- <b-row>
                <b-col class="px-2">
                    <b-form-group label="Select type user to change" >
                        <b-form-radio-group v-model="selected" class="text-center" :options="options" value-field="item" text-field="name">

                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
            </b-row> -->
            <!-- <b-row v-if="selected === 1"> 
                <b-col>
                    <ValidationProvider
                        rules="required"
                        v-slot="{ errors }">
                        <BFormGroup label="Select a agent">
                            <b-input-group>
                                <template #prepend>
                                    <b-input-group-text >
                                         <b-form-checkbox v-model="selected" name="check-button" />
                                    </b-input-group-text>
                                </template>
                                    <BFormSelect 
                                        v-model="users.agent"
                                        :options="usersLists.agents"
                                        :class="{'border-danger':errors[0]}"
                                        />
                            </b-input-group>
                        </BFormGroup>
                    </ValidationProvider>
                </b-col>
            </b-row> -->
            <b-row  >
                <b-col>
                    <ValidationProvider
                        rules="required"
                        v-slot="{ errors }">
                        <BFormGroup label="Select a specialist">
                            <b-input-group>
                                <template #prepend>
                                    <b-input-group-text >
                                         <b-form-checkbox v-model="users.specialist.active" name="check-button" />
                                    </b-input-group-text>
                                </template>
                                <BFormSelect
                                    v-model="users.specialist.model"
                                    :disabled="!(users.specialist.active)"
                                    :options="usersLists.specialists"
                                    :class="{'border-danger':errors[0]}"
                                    />
                            </b-input-group>
                        </BFormGroup>
                    </ValidationProvider>
                    
                </b-col>
            </b-row>
            <b-row  >
                <b-col>
                    <ValidationProvider
                        rules="required"
                        v-slot="{ errors }">                        
                        <BFormGroup label="Select a admin">
                            <b-input-group>
                                <template #prepend>
                                    <b-input-group-text >
                                         <b-form-checkbox v-model="users.admin.active" name="check-button" />
                                    </b-input-group-text>
                                </template>
                                    <BFormSelect
                                        v-model="users.admin.model"
                                        :disabled="!(users.admin.active)"
                                        :options="usersLists.administrators"
                                        :class="{'border-danger':errors[0]}"
                                        />
                            </b-input-group>
                        </BFormGroup>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <b-row class="my-1">
                <b-col>
                    <center>
                        <b-button @click="edit" variant="success">
                            UPDATE
                        </b-button>
                    </center>
                </b-col>
            </b-row>
        </ValidationObserver>
    </b-modal>
</template>
<script>
// Import services
import ConnectionUsersRelationshipService from '@/views/ce-digital/sub-modules/settings/views/users-relationship/service/users-relationship-connecion.service'
// Import Components
import AgentHeader from './AgentHeader.vue'


export default {
    components:{
        AgentHeader
    },
    props:{
        active: {
            type: Boolean,
            required: true,
        },
        data: {
            type: Object,
            required: true
        }
    },
    data(){return {
        isActive: false,
        users:{
            agent: {
                model: null,
                active: false
            },
            specialist: {
                model: null,
                active: false
            },
            admin: {
                model: null,
                active: false
            },
        },
        usersLists:{
            agents:[],
            specialists: [],
            administrators: [],
        },
        selected:null,
        options:[
            {
                item: 1,
                name: 'agent'
            },
            {
                item: 2,
                name: 'specialist'
            },
            {
                item: 3,
                name: 'administrator'
            }
        ],
        agent:null,
    }},
    async mounted(){
        
        await this.getUsers()

        this.isActive = this.active

        this.agent = this.data.agent

        Object.assign(this.users,{
            agent:{
                model: this.data.agent_id,
                active: false
            },
            specialist: {
                model: this.data.specialist_id,
                active: false
            },
            admin: {
                model: this.data.admin_id,
                active: false
            },
        })

    },
    methods:{
        async edit(){

            const isValid = await this.$refs.observator.validate()

            if(isValid)
                await this.editRelationship()

        },
        async editRelationship(){

            const params = {
                agent_id: this.users.agent.model,
                specialist_id: this.users.specialist.model,
                admin_id: this.users.admin.model
            }
            
            this.addPreloader()
                const {status, msg}  = await ConnectionUsersRelationshipService.editRelationship(params)
            this.removePreloader()

            if(status === 'success'){
                this.showSuccessSwal(msg)
                this.close(true)
            }
            else{
                this.showErrorSwal(msg[2])
            }


        },
        async getUsers(){
            const { data }  = await ConnectionUsersRelationshipService.relationshipUsers()

            Object.assign(this.usersLists, data)
        },
        close(refresh = false){
            this.$emit('close',refresh)
            this.isActive = false
        }

    },
}
</script>
