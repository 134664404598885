<template>
    <b-container fluid>
        <b-row class="mb-1">
            <b-col>
                <header-slot :clients-search="false" />
            </b-col>
            <b-col :cols="1" >
                <b-button @click="openNewRelationshipModal" variant="success" style="font-size: 15px;" >
                    <FeatherIcon icon="PlusIcon" size="20"  />
                    Create
                </b-button>
            </b-col>
        </b-row>
        <b-card>
            
        <filter-slot
                :filter="filters"
                :filter-principal="filterPrincipal"
                :total-rows="totalRows"
                :paginate="paginate"
                :start-page="startPage"
                :to-page="toPage"
                @reload="refresh"
                @reset-all-filters="refresh"
                >

            <template #table>
                <b-table
                    ref="connectionUsersRelationshipTable"
                    :items="provider"
                    :fields="fields"
                    table-class="text-nowrap"
                    responsive="sm"
                    :busy.sync="isBusy"
                    :current-page="paginate.currentPage"
                    :per-page="paginate.perPage"
                    show-empty
                    hover
                    
                >
                    <template #table-busy>
                        <div class="text-center text-primary my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>
                    <template #cell(date_assigned)="{value}" >
                        
                        <center>
                            {{ value | myGlobalWithHour }}
                        </center>
                        
                    </template>
                    <template #cell(tracking)="{item}" >
                        
                        <center>
                            <FeatherIcon @click="openRelationshipTrackingModal(item.agent_id)" icon="ListIcon" class="text-primary cursor-pointer" size="20"/>
                        </center>
                        
                    </template>
                    <template #cell(edit)="{item}" >
                                <center>
                                    <FeatherIcon @click="openEditRelationshipModal(item)" icon="Edit2Icon" class="text-warning cursor-pointer" size="20"/>
                                </center>
                    </template>
                    <template #cell(delete)="{item}" >
                                <center>
                                    <FeatherIcon @click="deleteRelationship(item.agent_id)" icon="Trash2Icon" class="text-danger cursor-pointer" size="20"/>
                                </center>
                    </template>
                </b-table>
                </template>   
        </filter-slot>
        </b-card>
        <NewRelationshipModal 
            v-if="activeNewRelationshipModal"
            :active="activeNewRelationshipModal"
            @close="closeNewRelationshipModal($event)"
        />
        <EditRelationshipModal 
            v-if="activeEditRelationshipModal"
            :active="activeEditRelationshipModal"
            :data="editData"
            @close="closeEditRelationshipModal($event)"
        />
        <RelationshipTrackingModal 
            v-if="activeRelationshipTrackingModal"
            :active="activeRelationshipTrackingModal"
            :agentId="agentId"
            @close="closeRelationshipTrackingModal"
            />

    </b-container>
</template>
<script>
// Import data
import {Fields,Filters,FilterPrincipal} from '@/views/ce-digital/sub-modules/settings/views/users-relationship/data/relationship.data'
// Import services
import ConnectionUsersRelationshipService from '@/views/ce-digital/sub-modules/settings/views/users-relationship/service/users-relationship-connecion.service'
// Import Components
import NewRelationshipModal from '@/views/ce-digital/sub-modules/settings/views/users-relationship/components/NewRelationshipModal.vue'
import RelationshipTrackingModal from '@/views/ce-digital/sub-modules/settings/views/users-relationship/components/RelationshipTrackingModal.vue'
import EditRelationshipModal from '@/views/ce-digital/sub-modules/settings/views/users-relationship/components/EditRelationshipModal.vue'

export default {
components:{
    NewRelationshipModal,
    RelationshipTrackingModal,
    EditRelationshipModal
},
created(){

        this.fields = Fields
        this.filters = Filters
        this.filterPrincipal = FilterPrincipal

},
async mounted(){

    await this.start()
},
data(){return {
    items: [],
    fields:[],
    filters:[],
    filterPrincipal:{},
    params:{
                agent_id : null,
                specialist_id : null,
                admin_id : null,
                new_user: null,
                type_user: null
            },
    paginate:{
                currentPage: 1,
                perPage: 50
            },
    totalRows:0,
    startPage:1,
    toPage:1,
    isBusy:false,
    activeNewRelationshipModal:false,
    activeRelationshipTrackingModal: false,
    activeEditRelationshipModal: false,
    agentId:null,
    editData:{},

}},
methods:{
    async start(){


        const  {data} = await ConnectionUsersRelationshipService.relationshipUsersAllAgents()
        const {agents,specialists,administrators} = data 
      

        this.filters[0].options = agents
        this.filters[1].options = specialists
        this.filters[2].options = administrators
    
    },
    async getRelationships(){
        
        this.isBusy = true

        Object.assign(this.params,{
                                        agent_id : this.filters[0].model,
                                        specialist_id : this.filters[1].model,
                                        admin_id : this.filters[2].model,
                                        per_page: this.paginate.perPage,
                                        page: this.paginate.currentPage

                                    })
                                    
        const { data,per_page,current_page,total }  = await ConnectionUsersRelationshipService.getRelationships(this.params)
        this.items = data 
        this.totalRows = total
        Object.assign(this.paginate,{
            perPage: per_page,
            currentPage: current_page
        })

        this.isBusy = false
    },
    async provider(){
        await this.getRelationships()
        return this.relationshipsList
    },
    async deleteRelationship(agent){

        const { isConfirmed } = await this.showConfirmSwal('Are you sure you want to delete this relationship?')

        if(!isConfirmed)
            return 
        
        const {data, status}  = await ConnectionUsersRelationshipService.deleteRelationship(agent)

        if(status === 200){
            this.showSuccessSwal(data.msg)
            this.refresh()
        }
        else{
            this.showErrorSwal(data.msg) 
        }

    },
    openNewRelationshipModal(){

        this.activeNewRelationshipModal = true
        
    },
    closeNewRelationshipModal(refresh = false){
        
        if(refresh)
            this.refresh()

        this.activeNewRelationshipModal = false

    },
    openEditRelationshipModal(agent){

        const {agent_id, specialist_id, admin_id,agent_names} = agent

        Object.assign(this.editData,{
            agent_id,
            specialist_id,
            admin_id,
            agent:agent_names
        })

        this.activeEditRelationshipModal = true
    },
    closeEditRelationshipModal(refresh){

        if(refresh)
            this.refresh()

        this.activeEditRelationshipModal = false
    },
    openRelationshipTrackingModal(agent){

        this.agentId = agent

        this.activeRelationshipTrackingModal = true
    },
    closeRelationshipTrackingModal(refresh){

        this.activeRelationshipTrackingModal = false
    },
    refresh(){
        this.$refs.connectionUsersRelationshipTable.refresh()
    }  
},
computed:{
    relationshipsList(){
        return this.items
    }
}
}
</script>
