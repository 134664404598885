<template>
    <b-modal v-model="isActive" size="xmd" @hidden="close" hide-footer >

        <b-table
            :items="items"
            :fields="fields"
            class="text-no-wrap"
            show-empty
            >
            <template #cell(created_at)="{value}" >
                {{value | myGlobalWithHour }}
            </template>
            <template #cell(status)="{value}" >
                <b-badge 
                    pill
                    style="font-size: 13px;"
                    class="px-1"
                    :variant="value == 'deleted' ? 'light-danger' : value == 'updated'? 'light-warning' : value == 'created'? 'light-success' : ''">
                    {{value}}
                </b-badge>
            </template>
        </b-table>

    </b-modal>
</template>
<script>
// Import Services
import ConnectionUsersRelationshipService from '@/views/ce-digital/sub-modules/settings/views/users-relationship/service/users-relationship-connecion.service'
// Import data
import {FieldsTracking} from '@/views/ce-digital/sub-modules/settings/views/users-relationship/data/relationship.data'

export default{
    props:{
        active:{
            type: Boolean,
            required:true
        },
        agentId:{
            type: Number,
            required: true
        }
    },
    async created(){
        await this.start()

        this.isActive = this.active

    },
    data() {return {
        isActive:false,
        items:[],
        fields:[],
    }},
    methods:{
        async start(){

            this.fields = FieldsTracking

            const data = await ConnectionUsersRelationshipService.getRelationshipTracking(this.agentId)
            
            this.items = data

        },
        close(){
            this.$emit('close')
            this.isActive = false
        }
    }
}
</script>
